module.exports = {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '指尖低碳管理',
  /**
   * @description token在Cookie中存储的天数， 默认1天
   */
  cookieExpires: 1,
  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: true,
  /**
   * @description api请求基础路径

   data2.ydbxgg.com
   api.gonggu.yuandun365.com:88
   */
  baseUrl: {
     pro:"https://datadt.yuandun365.com/",
    //  pro:"http://data.ditai.yuandun365.com/",

     // dev:"http://ditan.zhangmeng.com/",
    //  dev:"http://yd.data.ditai.yuandun365.com/",
     dev:"http://data.ditai.yuandun365.com/",
  
  },
  // 上传图片的URL
  uploadImgUrl: {
    dev:"https://datadt.yuandun365.com/common/file-save",
    pro:"https://datadt.yuandun365.com/common/file-save"
    //  dev:"http://data.ditai.yuandun365.com/common/file-save",
    //  pro:"http://data.ditai.yuandun365.com/common/file-save"

  },
  // 上传图片的URL
  uploadImgUrl2: {
    dev:"https://datadt.yuandun365.com/common/view-upload-file?loginIdent=1",
    pro:"https://datadt.yuandun365.com/common/view-upload-file?loginIdent=1"
    //  dev:"http://data.ditai.yuandun365.com/common/view-upload-file?loginIdent=1",
    //  pro:"http://data.ditai.yuandun365.com/common/view-upload-file?loginIdent=1"
  },
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: 'home',
  /**
   * @description 需要加载的插件
   */
  plugin: {
    'error-store': {
      showInHeader: true, // 设为false后不会在顶部显示错误日志徽标
      developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
    }
  },
   templateUrl: "https://datadt.yuandun365.com/upload/template",
  // templateUrl: "http://data.ditai.yuandun365.com/upload/template",
  export: 'http://data2.ydbxgg.com/third-interface/export-inquiry-price',
  gy_export: 'http://data2.ydbxgg.com/third-interface/export-bus-inquiry-price',
}
